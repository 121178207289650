import React, {useState, useRef, useEffect} from 'react';
import nationalLogo from '../assets/drapeau-national.png';
import groupeLogo from '../assets/networking.png';
import planningLogo from '../assets/planning.png';
import dashboardLogo from '../assets/dashboard.png';
import imageeval1 from "../assets/image1.webp";
import imageeval2 from '../assets/image2.webp';
import imageeval3 from '../assets/image3.webp';
import imageeval4 from '../assets/image4.webp';

import flecheDroiteLogo from '../assets/fleche-fine-contour-vers-la-droite.png';
import flecheGaucheLogo from '../assets/fleche-fine-contour-vers-la-gauche.png';

import imageProgression from '../assets/imageProgression.webp';
import imageDashboard from '../assets/imageDashBoard.webp';

import imageGroupe from  '../assets/imageGroupe.webp';

import { useMediaQuery } from 'react-responsive';
function Products({sectionRef}) {
    const [activeId, setActiveId] = useState("evaluations");  // Aucun ID actif initialement
    const isMd = useMediaQuery({ minWidth: 768, maxWidth: 1023 });
    const isSm = useMediaQuery({minWidth:0, maxWidth:768});
    const handleMenuClick = (item) => {
            setActiveId(item);

    }

    useEffect( () => {
        setActiveId("evaluations")
    },[])
    const scrollContainerRef = useRef(null);

    const handleNextClick = () => {
      if (scrollContainerRef.current) {
        console.log(scrollContainerRef.current)
        scrollContainerRef.current.scrollBy({ left: 300, behavior: 'smooth' });
      }
      isSm && setTimeout(() => {
        switch (activeId) {
          case 'evaluations':
            setActiveId('groupes');
            break;
          case 'groupes':
            setActiveId('progression');
            break;
          case 'progression':
            setActiveId('dashboard');
            break;
          case 'dashboard':
            setActiveId('evaluations');
            break;
          default:
            setActiveId('evaluations');
        }
      }, 300); // Le délai ici est en millisecondes (300ms)
  
    };
  

    const handlePrevClick = () => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollBy({ left: -300, behavior: 'smooth' });
      }
      isSm && setTimeout(() => {
        switch (activeId) {
          case 'evaluations':
            setActiveId('dashboard');
            break;
          case 'groupes':
            setActiveId('evaluations');
            break;
          case 'progression':
            setActiveId('groupes');
            break;
          case 'dashboard':
            setActiveId('progression');
            break;
          default:
            setActiveId('evaluations');
        }
      }, 300); // Le délai ici est en millisecondes (300ms)

    };

    return (
       <div ref={sectionRef} className="flex flex-row gap-20 justify-center   h-full bg-gradient-to-b align-middle  items-center  ">

{(!isMd&& !isSm) && <> 
    <div className=" lg:min-w-1/4 md:min-w-1/4 ">  </div> 
  <div className=" flex lg:flex-col md:flex-col gap-8  3xl:w-1/4 items-start p-5 lg:w-1/3 md:w-10/12   "> 
            
            <h1 className=' font-sans tracking-wide  4xl:text-3xl 3xl:text-2xl 2xl:text-xl xl:text-lg uppercase text-left  '> Initiation à TanoEd</h1>
  <h1 className=' font-sans tracking-wide font-bold 4xl:text-4xl 3xl:text-3xl 2xl:text-2xl xl:text-xl text-left  '>  Propulsez chaque élève vers l'excellence en mathématiques grâce à une
  <span className=' text-sky-600'>  pédagogie data-driven</span>.
  </h1>
   <p className='   tracking-wide 4xl:text-xl 3xl:text-lg 2xl:text-base xl:text-sm lg:text-xs  text-justify '>
Transformez les données en stratégies d'apprentissage efficaces et engagez chaque élève sur la voie du succès.
   </p>

   <div 
                    className={`flex flex-row items-center gap-8 tracking-wide p-5 rounded-2xl border-2 hover:cursor-pointer  transition duration-300 ease-in-out
                    ${activeId === 'evaluations' ? 'border-sky-500 bg-sky-200 bg-opacity-10' : 
                    'border-transparent hover:border-sky-300 hover:bg-sky-200 hover:bg-opacity-10'}`}
                    onClick={() => handleMenuClick('evaluations')}
                >
    <img src={nationalLogo} alt="nat"  className='   xl:w-16 lg:w-11'/>
    <div className=' flex flex-col text-left gap-2'>
        <p className=' font-bold 4xl:text-2xl 3xl:text-xl 2xl:text-base  '> Evaluations normalisées </p>
        <p className='4xl:text-lg 3xl:text-base 2xl:text-sm xl:text-sm lg:text-xs'> Optimisez l'évaluation avec TanoEd : de l'importation simplifiée à l'analyse détaillée, facilitez le suivi du progrès éducatif avec précision et efficacité.</p>
        <p className=' text-sky-600 font-bold hover:text-sky-800 hover:translate-x-7 transition duration-300 ease-in-out w-fit  2xl:text-sm 
        xl:text-sm lg:text-xs'> En savoir plus ⮕</p>
    </div>

</div>


<div     className={`flex flex-row items-center gap-8 tracking-wide p-5 rounded-2xl border-2 hover:cursor-pointer  transition duration-300 ease-in-out
                    ${activeId === 'groupes' ? 'border-sky-500 bg-sky-200 bg-opacity-10' : 
                    'border-transparent hover:border-sky-300 hover:bg-sky-200 hover:bg-opacity-10'}`}
                    onClick={() => handleMenuClick('groupes')} >
    <img src={groupeLogo} alt="nat"  className='   xl:w-16 lg:w-11'/>
    <div className=' flex flex-col text-left gap-2'>
        <p className=' font-bold 4xl:text-2xl 3xl:text-xl 2xl:text-base '> Groupes de besoins. </p>
        <p className=' 4xl:text-lg 3xl:text-base 2xl:text-sm xl:text-sm lg:text-xs'> 
        Créez et gérez des groupes de besoins personnalisés adapté à la réforme en quelques clics.
</p>
        
<p className=' text-sky-600 font-bold hover:text-sky-800 hover:translate-x-7 transition duration-300 ease-in-out w-fit 2xl:text-sm xl:text-sm lg:text-xs'> En savoir plus ⮕</p>
    </div>

</div>

<div   className={`flex flex-row items-center gap-8 tracking-wide p-5 rounded-2xl border-2 hover:cursor-pointer  transition duration-300 ease-in-out
                    ${activeId === 'progression' ? 'border-sky-500 bg-sky-200 bg-opacity-10' : 
                    'border-transparent hover:border-sky-300 hover:bg-sky-200 hover:bg-opacity-10'}`}
                    onClick={() => handleMenuClick('progression')} >
    <img src={planningLogo} alt="nat"  className='   xl:w-16 lg:w-11'/>
    <div className=' flex flex-col text-left gap-2'>
        <p className=' font-bold 4xl:text-2xl 3xl:text-xl 2xl:text-base '> Progression </p>
        <p className=' 4xl:text-lg 3xl:text-md 2xl:text-sm xl:text-sm lg:text-xs'> 
        Créez des plans de progression communes ou personnalisées alignés avec Éduscol pour chaque niveau et groupe.
</p>
        
<p className=' text-sky-600 font-bold hover:text-sky-800 hover:translate-x-7 transition duration-300 ease-in-out w-fit  2xl:text-sm xl:text-sm lg:text-xs'> En savoir plus ⮕</p>
    </div>

</div>

<div  className={`flex flex-row items-center gap-8 tracking-wide p-5 rounded-2xl border-2 hover:cursor-pointer  transition duration-300 ease-in-out 
                    ${activeId === 'dashboard' ? 'border-sky-500 bg-sky-200 bg-opacity-10  ' : 
                    'border-transparent hover:border-sky-300 hover:bg-sky-200 hover:bg-opacity-10'}`}
                    onClick={() => handleMenuClick('dashboard')} >
    <img src={dashboardLogo} alt="nat"  className='    xl:w-16 lg:w-11'/>
    <div className=' flex flex-col text-left gap-2'>
        <div className=' font-bold 4xl:text-2xl 3xl:text-xl 2xl:text-base flex flex-row '> Acquisition de savoirs-faires
        <div>
            <p className='  text-xs text-blue-600 underline italic font-sans'> En validation </p>
            <p></p>
        </div>
         </div>
        
        <p className=' 4xl:text-lg 3xl:text-md 2xl:text-sm xl:text-sm lg:text-xs'> 
       
        Utilisez des tableaux de bord interactifs pour évaluer et différencier facilement les performances par compétence et savoir-faire.

</p>
        
<p className=' text-sky-600 font-bold hover:text-sky-800 hover:translate-x-7 transition duration-300 ease-in-out w-fit  2xl:text-sm xl:text-sm lg:text-xs'> En savoir plus ⮕</p>
    </div>

</div>





            </div>

     <div className=" flex flex-row xl:w-1/2 lg:w-1/2 md:w-fit gap-5 h-2/3 items-center justify-center align-middle   ">
     {activeId ==="evaluations" &&   

<>
<div className=' flex flex-col  justify-between gap-20  animated-div  test  '>


<div className='floating-ui' > 
   <img src={imageeval1} alt="landing" className='  h-auto w-auto '  />
   
   </div> 


   <div className= 'floating-ui   '> 
   <img src={imageeval2} alt="landing" className='  h-auto w-auto '  />
   
   </div> 


   </div>


     <div className=' flex flex-col gap-20 animated-div test  '>
       
<div className= 'floating-ui   '> 
   <img src={imageeval3} alt="landing" className='   h-auto w-auto '  />
   
   </div> 

   <div className= 'floating-ui   '> 
   <img src={imageeval4} alt="landing" className='   h-auto w-auto '  />
   
   </div> 

   




   </div> 
           </> 
        }


           {activeId === "groupes" && <div className=' animated-div'>
                <img src ={imageGroupe} alt="test"/> 
            </div> }


            {activeId === "progression" && <div className=' animated-div'>
                <img src ={imageProgression} alt="test"/> 
            </div> }

            {activeId === "dashboard" && <div className=' animated-div'>
                <img src ={imageDashboard} alt="test"/> 
            </div> }
</div> 

</> }

{(isMd || isSm) && <div className=' flex flex-col w-full h-full justify-center  p-5 gap-5 '>
    <h1 className=' font-sans tracking-wide text-xl uppercase text-left  '> Initiation à TanoEd</h1>
  <h1 className=' font-sans tracking-wide font-bold text-2xl text-left  '>  Propulsez chaque élève vers l'excellence en mathématiques grâce à une
  <span className=' text-sky-600'>  pédagogie data-driven</span>.
  </h1>
   <p className='   tracking-wide text-lg  text-justify '>
Transformez les données en stratégies d'apprentissage efficaces et engagez chaque élève sur la voie du succès.
   </p>
   <div ref={scrollContainerRef} className=' flex flex-row  overflow-hidden w-full scroll-container gap-2'>

    <div className={`flex-none ${isMd ? 'w-1/2' : 'w-full'}   scroll-item`}>

   <div 
                    className={`flex flex-col  gap-8 tracking-wide p-5 rounded-2xl border-2 hover:cursor-pointer  transition duration-300 ease-in-out 
                    ${activeId === 'evaluations' ? 'border-sky-500 bg-sky-200 bg-opacity-10' : 
                    'border-transparent hover:border-sky-300 hover:bg-sky-200 hover:bg-opacity-10'}`}
                    onClick={() => handleMenuClick('evaluations')}
                >
    <img src={nationalLogo} alt="nat"  className=' md:w-10 w-40'/>
    <div className=' flex flex-col text-left gap-2'>
        <p className=' font-bold 4xl:text-2xl 3xl:text-xl 2xl:text-base  '> Evaluations normalisées </p>
        <p className='4xl:text-lg 3xl:text-base 2xl:text-sm xl:text-sm lg:text-xs'> Optimisez l'évaluation avec TanoEd : de l'importation simplifiée à l'analyse détaillée, facilitez le suivi du progrès éducatif avec précision et efficacité.</p>
        <p className=' text-sky-600 font-bold hover:text-sky-800 hover:translate-x-7 transition duration-300 ease-in-out w-fit  2xl:text-sm 
        xl:text-sm lg:text-xs'> En savoir plus ⮕</p>
    </div>

</div>

</div>



<div className={`flex-none ${isMd ? 'w-1/2' : 'w-full'}   scroll-item`}>

<div     className={`flex flex-col  gap-8 tracking-wide p-5 rounded-2xl border-2 hover:cursor-pointer  transition duration-300 ease-in-out 
                    ${activeId === 'groupes' ? 'border-sky-500 bg-sky-200 bg-opacity-10' : 
                    'border-transparent hover:border-sky-300 hover:bg-sky-200 hover:bg-opacity-10'}`}
                    onClick={() => handleMenuClick('groupes')} >
    <img src={groupeLogo} alt="nat"  className=' md:w-10 w-40'/>
    <div className=' flex flex-col text-left gap-2'>
        <p className=' font-bold 4xl:text-2xl 3xl:text-xl 2xl:text-base '> Groupes de besoins. </p>
        <p className=' 4xl:text-lg 3xl:text-base 2xl:text-sm xl:text-sm lg:text-xs'> 
        Créez et gérez des groupes de besoins personnalisés adapté à la réforme en quelques clics.
</p>
        
<p className=' text-sky-600 font-bold hover:text-sky-800 hover:translate-x-7 transition duration-300 ease-in-out w-fit 2xl:text-sm xl:text-sm lg:text-xs'> En savoir plus ⮕</p>
    </div>

    </div>

</div>



<div className={`flex-none ${isMd ? 'w-1/2' : 'w-full'}   scroll-item`}>


<div   className={`flex flex-col gap-8 tracking-wide p-5 rounded-2xl border-2 hover:cursor-pointer  transition duration-300 ease-in-out
                    ${activeId === 'progression' ? 'border-sky-500 bg-sky-200 bg-opacity-10' : 
                    'border-transparent hover:border-sky-300 hover:bg-sky-200 hover:bg-opacity-10'}`}
                    onClick={() => handleMenuClick('progression')} >
    <img src={planningLogo} alt="nat"  className=' md:w-10 w-40'/>
    <div className=' flex flex-col text-left gap-2'>
        <p className=' font-bold 4xl:text-2xl 3xl:text-xl 2xl:text-base '> Progression </p>
        <p className=' 4xl:text-lg 3xl:text-md 2xl:text-sm xl:text-sm lg:text-xs'> 
        Créez des plans de progression communes ou personnalisées alignés avec Éduscol pour chaque niveau et groupe.
</p>
        
<p className=' text-sky-600 font-bold hover:text-sky-800 hover:translate-x-7 transition duration-300 ease-in-out w-fit  2xl:text-sm xl:text-sm lg:text-xs'> En savoir plus ⮕</p>
    </div>

</div>

</div>



<div className={`flex-none ${isMd ? 'w-1/2' : 'w-full'}   scroll-item`}>


<div  className={`flex flex-col  gap-8 tracking-wide p-5 rounded-2xl border-2 hover:cursor-pointer  transition duration-300 ease-in-out 
                    ${activeId === 'dashboard' ? 'border-sky-500 bg-sky-200 bg-opacity-10  ' : 
                    'border-transparent hover:border-sky-300 hover:bg-sky-200 hover:bg-opacity-10'}`}
                    onClick={() => handleMenuClick('dashboard')} >
    <img src={dashboardLogo} alt="nat" className=' md:w-10 w-40'/>
    <div className=' flex flex-col text-left gap-2'>
        <div className=' font-bold 4xl:text-2xl 3xl:text-xl 2xl:text-base flex flex-row '> Acquisition de savoirs-faires
        <div>
            <p className='  text-xs text-blue-600 underline italic font-sans'> En validation </p>
            <p></p>
        </div>
         </div>
        
        <p className=' 4xl:text-lg 3xl:text-md 2xl:text-sm xl:text-sm lg:text-xs'> 
       
        Utilisez des tableaux de bord interactifs pour évaluer et différencier facilement les performances par compétence et savoir-faire.

</p>
        
<p className=' text-sky-600 font-bold hover:text-sky-800 hover:translate-x-7 transition duration-300 ease-in-out w-fit  2xl:text-sm xl:text-sm lg:text-xs'> En savoir plus ⮕</p>
    </div>

    </div>
    </div> 
</div> 

<div className=' flex flex-row justify-between'>
   {activeId !== "evaluations" ?  <img onClick={handlePrevClick} src={flecheGaucheLogo} className=' w-8 hover:cursor-pointer' alt="prev" /> : <span> </span> }
   {activeId !=="dashboard" &&  <img onClick={handleNextClick} src={flecheDroiteLogo} className=' w-8 hover:cursor-pointer' alt="next" />}
</div> 



<div className=" flex flex-row w-full h-full   ">
     {activeId ==="evaluations" &&   

<>
<div className=' flex flex-col  justify-between gap-20  animated-div  test  '>


<div className='floating-ui' > 
   <img src={imageeval1} alt="landing" className='  h-auto w-auto '  />
</div> 


   <div className= 'floating-ui   '> 
   <img src={imageeval2} alt="landing" className='  h-auto w-auto '  />
   </div> 


   </div>


     <div className=' flex flex-col gap-20 animated-div test  '>
       
<div className= 'floating-ui   '> 
   <img src={imageeval3} alt="landing" className='   h-auto w-auto '  />
   
   </div> 

   <div className= 'floating-ui   '> 
   <img src={imageeval4} alt="landing" className='   h-auto w-auto '  />
   
   </div> 

   




   </div> 
           </> 
        }


           {activeId === "groupes" && <div className=' animated-div'>
                <img src ={imageGroupe} alt="test"/> 
            </div> }


            {activeId === "progression" && <div className=' animated-div'>
                <img src ={imageProgression} alt="test"/> 
            </div> }

            {activeId === "dashboard" && <div className=' animated-div'>
                <img src ={imageDashboard} alt="test"/> 
            </div> }
</div> 
    </div> }
    
        </div>



    );
  }
  
  export default Products;