// Dans Header.js
import React, {useState} from "react";
import emailjs from 'emailjs-com';
function ContactForm({handleAccueilClick}) {

const [nom,setNom] = useState("")
const [prenom,setPrenom] = useState('')
const [tel,setTel] = useState('')
const [mail, setMail] = useState('')
const [subject, setSubject] = useState('')
const [message, setMessage] = useState('')
const [isValidEmail, setIsValidEmail] = useState(false);

const handleNomChange = (e) => {
    console.log(e)
    setNom(e.target.value);
}
const handlePrenomChange = (e) => {
    console.log(e);
    setPrenom(e.target.value);
}

const handleTelChange = (e) => {
    const value = e.target.value;
    // Vérifiez si la valeur contient uniquement des chiffres de 0 à 9
    if (/^\d*$/.test(value)) {
        setTel(value);
    } else {
        console.log("Seuls les chiffres de 0 à 9 sont acceptés");
    }
}

const handleMailChange = (e) => {
    console.log(e);
    setMail(e.target.value);
    setIsValidEmail(validateEmail(mail));
}

const handleSubjectChange = (e) => {
    console.log(e);
    setSubject(e.target.value);
}

const handleMessageChange = (e) => {
    console.log(e);
    setMessage(e.target.value);
}
const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
}
const sendEmail = (e) => {
    console.log("send email call ")
    e.preventDefault();

    if (!isValidEmail) {
        alert("Veuillez entrer une adresse mail valide.");
        return;
    }

    const templateParams = {
        from_name: nom + ' ' + prenom,
        to_name: 'Destinataire', // Remplacez par le nom du destinataire
        message: message,
        reply_to: mail,
    };

    emailjs.send('service_fi933xq', 'template_ue9pas4', templateParams, 'YPJ2MTBZ_dfLyx8Up')
        .then((result) => {
            console.log(result.text);
            alert("Message envoyé avec succès !");
            handleAccueilClick()
        }, (error) => {
            console.log(error.text);
            alert("Une erreur s'est produite, veuillez réessayer.");
        });
};
    return (
        <div className=" h-full w-full "  style={{backgroundImage: 'linear-gradient(90deg, #5de0e6, #004aad)'}} > 
   
   
   
   <div className=" w-full  flex flex-col justify-center items-center   p-10 "   >

   <div className=" mt-20 flex flex-col bg-white rounded-3xl shadow-sm shadow-black text-left p-10 gap-8">
   <h1 className=" font-sans md:text-4xl text-2xl">Demandez plus d'informations</h1>
        <form  className=" font-sans text-base flex flex-col gap-2  w-full" action="/submit-form" method="post">
           <div className=" flex md:flex-row flex-col gap-5">

           <div className=" flex flex-col"> <label className=" font-sans md:text-xl text-base" for="name">Nom</label>
            <input className=" md:text-xl text-base  input-field w-full   " 
            placeholder="Votre nom" type="text" id="name" name="name" value={nom} onChange={ handleNomChange} required />
            {!nom && <p className=" text-orange-500 font-sans "> Veuillez entrer un nom.</p>}
            </div>

            <div className=" flex flex-col">

<label className=" font-sans md:text-xl text-base" for="name">Prénom</label>
            <input onChange={ handlePrenomChange} className="md:text-xl text-base  input-field   " 
            placeholder="Votre prénom" type="text" id="name" name="name" required />
 
            </div> 
            </div> 
            
            <label className=" font-sans md:text-xl text-base" for="email">Email</label>
            <input onChange={ handleMailChange} className=" md:text-xl text-base md:w-1/2 w-full  input-field   " placeholder=" exemple@gmail.com"  type="email" id="email" name="email" required />
            {!isValidEmail && <p className=" text-orange-500 font-sans "> Veuillez entrer une adresse mail valide.</p>}
            <label className=" font-sans md:text-xl text-base" for="phone">Téléphone (facultatif)</label>
            <input onChange={ handleTelChange} className=" md:text-xl text-base w-1/2  input-field   "  value={tel} placeholder=" 0262" type="tel" id="phone" name="phone" />
            
            <label className=" font-sans md:text-xl text-base" for="subject">Sujet</label>
            <input onChange={ handleSubjectChange} className=" md:text-xl text-base w-full  input-field   "  type="text" id="subject" name="subject" required />
            {!subject && <p className=" text-orange-500 font-sans "> Veuillez entrer le sujet du mail.</p>}
            <label className=" font-sans md:text-xl text-base"  for="message">Message</label>
            <textarea onChange={ handleMessageChange} className=" md:text-xl text-base  input-field   "   id="message" name="message" rows="5" required></textarea>
            {!message && <p className=" text-orange-500 font-sans "> Veuillez entrer votre message.</p>}
           
        </form>

        <div className=" justify-center
            items-center  align-middle text-center w-full"
            >
          {(nom && mail && subject && message &&isValidEmail) &&   <button onClick={sendEmail} className="  p-5 bg-blue-400 rounded-3xl justify-center
             items-center align-middle text-center w-fit text-black font-sans
              uppercase hover:bg-sky-200   " type="submit">Envoyer</button>}

            </div>
        <div class="contact-info">
            <p><strong>Adresse :</strong> 50 Bis Rn2 Bras-Panon 97412</p>
            <p><strong>Téléphone :</strong> 0692 26 51 74</p>
            <p><strong>Email :</strong> <a  className = "underline text-blue-600" href="mailto:mangata@tanoed.com">mangata@tanoed.com</a></p>
        </div> 

        </div> 

    </div>
    </div> 
    );
  }
  
  export default ContactForm;