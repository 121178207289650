import React from 'react';
import projetFrancais from '../assets/projetfrançais.webp';
import matiereFr from '../assets/matiereFrancais.png';
import robotIA from '../assets/robotIA.webp';
import projetIA from '../assets/projetIA.webp';
import { useMediaQuery } from 'react-responsive';
function Projet({sectionRef}) {

  const isMd = useMediaQuery({ minWidth: 768, maxWidth: 1023 });
  const isSm = useMediaQuery({minWidth:526, maxWidth:768});
  const isextraSm = useMediaQuery({minWidth:0, maxWidth:526});
    return (
  
<div  ref={sectionRef} className={`flex flex-col background-container h-full items-center text-center align-middle  `}>
  {(!isMd  && !isSm &&!isextraSm) && <> <div className="flex flex-col text-center   " > {/* Ce composant s'étend sur deux colonnes */}
  <h1 className=' font-bold 4xl:text-6xl 3xl:text-5xl tracking-wide p-3 mt-5 text-slate-200 2xl:text-4xl xl:text-4xl lg:text-2xl '> Vers l'Avenir : Nouveautés à Venir </h1>

<p className=' 4xl:text-3xl 3xl:text-2xl 2xl:text-xl xl:text-xl
lg:text-lg font-normal mt-5 text-slate-200'> Découvrez ce que nous préparons pour transformer l'apprentissage personnalisé
<br/> et rendre les évaluations encore plus efficaces. </p>
  </div>
  <div className=' flex flex-row p-5 bg-slate-100 rounded-3xl shadow-sm shadow-zinc-700 h-fit w-1/2  mt-10  items-center justify-center  xl:w-1/2  lg:w-2/3' >  {/* Composant standard dans une cellule */}
    {/* Contenu du composant */}
    <div className='  flex flex-col w-1/3 gap-8 tracking-wide text-left'>
   <img src={matiereFr} alt="matiere" className='  w-20' /> <h1 className=' font-bold font-sans 4xl:text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg
   lg:text-base '> Prochainement : Extension au Français  </h1>

   <p className=' 4xl:text-xl  2xl:text-base xl:text-sm lg:text-xs'>
   Tout comme notre module de mathématiques, nous élargissons notre plateforme au français, optimisant l'exploitation des évaluations nationales
    pour une création efficace de groupes de besoins adaptés aux réformes éducatives. <br /> <br /> 
   Notre technologie avancée permet aux enseignants de gérer les évaluations et de personnaliser l'apprentissage afin de répondre
    précisément aux besoins de chaque discipline.
   </p>

        
        </div>

        <div className=' flex  w-2/3 h-full items-center align-middle justify-center  '>
    <img src={projetFrancais} alt="fr" className=' w-full ' /> 

    </div> 
  </div>
 
  {/* Continuez à ajouter d'autres composants selon le besoin */}
  <div className=' flex  p-5 bg-white rounded-3xl shadow-sm shadow-zinc-700 h-fit xl:w-1/2  lg:w-2/3
  mt-10
  items-center
   ' > {/* Un autre composant standard */}
    {/* Contenu du composant */}

    <div className='  flex flex-col w-1/3 gap-8 
    tracking-wide text-left align-middle justify-center '>
   <img src={robotIA} alt="matiere" className='  w-28' /> <h1 className=' font-bold font-sans 4xl:text-2xl 3xl:text-2xl 2xl:test-base
   lg:text-sm  '> Intégration imminente de l'IA pour une aide personnalisé </h1>

   <p className='4xl:text-xl 3xl:text-md 2xl:text-base xl:text-sm lg:text-xs'>
   Nous intégrons l'intelligence artificielle pour transformer l'expérience éducative, utilisant
    les données stockées dans notre base pour personnaliser profondément l'apprentissage et renforcer
     l'engagement des élèves en difficulté.
     
     <br /> <br />  Notre IA analyse les profils d'apprentissage et les progrès individuels
      pour générer des questions et exercices adaptés, 
   optimisant ainsi l'efficacité pédagogique et l'adaptation à chaque élève.
   </p>

        
        </div>

        <div className=' flex  w-2/3 h-full items-center align-middle justify-center '>
    <img src={projetIA} alt="fr" className=' w-auto h-auto    ' /> 

    </div> 


  </div>
  </> }

  {(isMd||isSm || isextraSm) && 
  <div className=' flex flex-col gap-8'>
     
    <div className="flex flex-col text-center    " > {/* Ce composant s'étend sur deux colonnes */}
  <h1 className=' font-bold 4xl:text-6xl 3xl:text-5xl tracking-wide p-3 mt-5 text-slate-200 2xl:text-4xl xl:text-4xl lg:text-2xl text-xl '> Vers l'Avenir : Nouveautés à Venir </h1>

<p className=' 4xl:text-3xl 3xl:text-2xl 2xl:text-xl xl:text-xl
lg:text-lg font-normal mt-5 text-slate-200 text-base'> Découvrez ce que nous préparons pour transformer l'apprentissage personnalisé
<br/> et rendre les évaluations encore plus efficaces. </p>
  </div>


  <div className=' flex flex-col p-5 bg-slate-100 rounded-3xl shadow-sm shadow-zinc-700 h-fit 
  sm:w-3/4 w-full sm:m-20 m-2 tracking-wide  ' >  {/* Composant standard dans une cellule */}
    {/* Contenu du composant */}
    <div className='  flex sm:flex-row flex-col w-full items-center gap-8 tracking-wide text-left'>
   <img src={matiereFr} alt="matiere" className='  w-20' /> <h1 className=' font-bold font-sans 4xl:text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg
   sm:text-base text-xs '> Prochainement : Extension au Français  </h1>
    </div>

    <div className=' flex '>
    {!isextraSm && <p className='  text-base p-2 text-left'>
   Nous optimisons l'exploitation des évaluations nationales pour créer des groupes de besoins adaptés aux réformes éducatives. 
   <br /> Notre technologie avancée permet aux enseignants de gérer les évaluations et de personnaliser l'apprentissage pour répondre aux besoins spécifiques de chaque discipline
   </p> }

        
        </div>

        <div className=' flex  w-full h-full items-center align-middle justify-center  '>
    <img src={projetFrancais} alt="fr" className=' w-full ' /> 

    </div> 
  </div>
 
  {/* Continuez à ajouter d'autres composants selon le besoin */}
  <div className=' flex flex-col  p-5 bg-white rounded-3xl shadow-sm shadow-zinc-700 h-fit 
  m-2
  items-center
    w-full sm:w-3/4 sm:m-20  ' > {/* Un autre composant standard */}
    {/* Contenu du composant */}

    <div className='  flex sm:flex-row flex-col w-full tracking-wide text-left 
     items-center
    align-middle '>
   <img src={robotIA} alt="matiere" className='  w-28' /> 
   <h1 className=' font-bold font-sans 4xl:text-2xl 3xl:text-2xl 2xl:test-base
   sm:text-sm text-xs  '> Intégration imminente de l'IA pour une aide personnalisé </h1>
    </div>
    <div className=' text-left  p-5'>
   {!isextraSm && <p className='4xl:text-xl 3xl:text-md 2xl:text-base xl:text-sm lg:text-xs'>
   Nous intégrons l'IA pour personnaliser l'apprentissage et renforcer l'engagement des élèves en difficulté. <br/> 
   Notre IA analyse les profils et les progrès pour générer des exercices adaptés, optimisant ainsi l'efficacité pédagogique pour chaque élève.
</p> }
        
        </div>

        <div className=' flex  w-full h-full items-center align-middle justify-center '>
    <img src={projetIA} alt="fr" className=' w-auto h-auto    ' /> 

    </div> 


  </div>
    </div>}

</div>
    );
  }
  
  export default Projet;