import Header from './components/Header';
import Banner from './components/Banner';
import './App.css';
import Products from './components/Products';
import Projet from './components/Projet';
import Why from './components/Why';
import Idea from './components/Idea';
import GetIt from './components/GetIt';
import Footer from './components/Footer';
import Coordonnées from './components/Coordonnées';
import { useState, useRef, useEffect } from 'react';
import MentionLegales from './components/MentionLegales';
import Politique from './components/Politique';
import ContactForm from './components/ContactForm';

function App() {

  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);


  const [scrollTarget, setScrollTarget] = useState(null);

  const scrollToSection = (ref, offset = 50) => {
    if (ref.current) {
      const elementPosition = ref.current.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  
  const [menu, setMenu] = useState("accueil")
  useEffect(() => {
    if (scrollTarget) {
      scrollToSection(scrollTarget.ref, scrollTarget.offset);
      setScrollTarget(null); // Reset the scroll target after scrolling
    }
  }, [menu, scrollTarget]);


  const handleMentionClick = () => {
    setMenu("mentions");
    window.scrollTo({ top: 0, behavior: 'smooth' });

  }

  const handleAccueilClick = () => {
    console.log("CLIQUER" );
    setMenu("accueil");
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const handlePolitiqueClick = () => {
    setMenu("politique");
    window.scrollTo({top : 0 , behavior:'smooth'})
  }

  const handleProductsClick = () => {
    console.log("products")
    setMenu('accueil')
    setScrollTarget({ ref: section1Ref, offset: 100 });
    
  }

  const handleWhyClick= () => {
    setMenu('accueil')
    setScrollTarget({ ref: section2Ref, offset: 100 });
  }

  const handleProjetClick = () => {
    setMenu('accueil')
    setScrollTarget({ ref: section3Ref, offset: 100 });
  }

  const handleContactFormClick = () => {
    console.log("contact click !")
    setMenu("contact")
    window.scrollTo({ top: 0, behavior: 'smooth' });

  }
  return (
    <div className="App">

    
      <Header handleAccueilClick={handleAccueilClick} handleProductsClick={handleProductsClick} handleWhyClick={handleWhyClick} 
      handleProjetClick={handleProjetClick} handleContactFormClick={handleContactFormClick}  /> 
      {menu === "accueil" && 
      <>
      <Banner handleProductsClick={handleProductsClick} /> 
      <Products sectionRef={section1Ref}  /> 

      <Projet sectionRef={section3Ref} /> 
      <Why sectionRef={section2Ref} />
     <Idea /> 
     <GetIt handleContactFormClick={handleContactFormClick} /> 

   
     
     </> }

     {menu === "mentions" && 
     <MentionLegales /> 
     }

     {menu ==="politique" && 
     <Politique /> }

     {menu ==="contact" && 
     <ContactForm handleAccueilClick={handleAccueilClick}/> }

<Footer  handleProductsClick={handleProductsClick} handleContactFormClick={handleContactFormClick}  /> 

<Coordonnées handleMentionClick={handleMentionClick} handlePolitiqueClick={handlePolitiqueClick} /> 
    </div>
  );
}

export default App;
