import React from 'react';


function MentionLegales() {
    return (
        <div className="flex flex-row gap-5  text-left  bg-gradient-to-b  p-5 w-full  mt-20 tracking-wide ">
    
 <div className=' lg:w-1/4 w-1/6'>  </div>
<div className=' flex flex-col lg:w-1/2 w-full gap-5 mt-5 p-5'> 
    <h1 className=' font-sans text-2xl '>Mentions Légales</h1>
    <p><strong>Le présent site web est édité par :</strong></p>
    <p>Nom de l’entreprise : Tano Solutions<br/>
    Forme juridique : Entreprise Individuelle (E.I.R.L.)<br/>
    Adresse du siège social : 50 Bis RN2 Bras-Panon, 97412 La Réunion<br/>
    Numéro de téléphone : +262 (0)692 26 51 74<br/>
    Adresse e-mail : [non renseignée]<br/>
    Numéro SIREN : 928 083 773 00035</p>

    <p>Le directeur de la publication est : Nicky MANGATA</p>

    <p><strong>Le présent site web est hébergé par :</strong></p>
    <p>Nom de l’hébergeur :   Namecheap, Inc.<br/>
  
    Adresse : 4600 East Washington Street, Suite 305
Phoenix, AZ 85034
USA<br/>
    Site web : <a href="https://www.namecheap.com" >https://www.namecheap.com/</a></p>

    <h2 className=' font-sans text-3xl'>CONDITIONS GÉNÉRALES D’UTILISATION</h2>
    <p>Le présent site web est accessible à l’adresse suivante : <a href="https://www.tanoed.com" >www.tanoed.com</a></p>
    <p>L’utilisation du site web est soumise aux conditions générales d’utilisation accessibles à l’adresse suivante :
         <a href="https://www.tanoed.com" >www.tanoed.com</a>.</p>

    <h2 className=' font-sans text-3xl'>PROPRIÉTÉ INTELLECTUELLE</h2>
    <p>Le contenu du présent site web est la propriété exclusive de Tano Solutions et/ou de ses partenaires et est protégé par les lois françaises et internationales
         relatives à la propriété intellectuelle.</p>
    <p>Toute reproduction, représentation, diffusion ou exploitation de tout ou partie du contenu du site web,
         sans autorisation préalable écrite de Tano Solutions, est strictement interdite et peut constituer un délit de contrefaçon.</p>

    <h2 className=' font-sans text-3xl'>RESPONSABILITÉ</h2>
    <p>Tano Solutions s’efforce d’assurer l’exactitude et la mise à jour des informations diffusées sur le site web, 
        mais ne peut garantir l’exactitude, la fiabilité ou l’exhaustivité de ces informations.</p>
    <p>En conséquence, Tano Solutions décline toute responsabilité pour toute imprécision, inexactitude ou omission portant
         sur des informations disponibles sur le site web, ainsi que pour tout dommage résultant d’une intrusion frauduleuse d’un tiers
          ayant entraîné une modification des informations diffusées sur le site web.</p>

    <h2 className=' font-sans text-3xl'>DROIT APPLICABLE ET JURIDICTION</h2>
    <p>Les présentes mentions légales sont régies par le droit français.</p>
    <p>Tout litige relatif aux présentes mentions légales sera soumis à la compétence exclusive des tribunaux français.</p>
</div> 
</div> 

    )
  }
  
  export default MentionLegales;