// Dans Banner.js
import landingImage from '../assets/landingpage.webp';
import { useMediaQuery } from 'react-responsive';

function Banner({handleProductsClick}) {
  const isMd = useMediaQuery({ minWidth: 400, maxWidth: 1280 });
  const isextraSm = useMediaQuery({ minWidth: 0, maxWidth: 400 });
    return (
        <>
        <div className='   lg:h-52  h-30' style={{ backgroundColor: '#a2d9ff' }}>  </div>
        
      <div className="  bg-center h-full " style={{ backgroundColor: '#a2d9ff'}}>

      

        <div className="flex lg:flex-row flex-col    lg:justify-center justify-normal lg:items-end items-center p-10   h-full ">

        <div className=" min-w-1/4">  </div>

            <div className=" flex lg:flex-col flex-col gap-8  lg:w-1/4 items-start p-5 w-full  "> 

            <h1 className="mt-5 4xl:text-6xl 3xl:text-5xl 2xl:text-4xl xl:text-3xl lg:text-2xl
            text-2xl
            
            font-bold font-sans  text-left  tracking-wider ">
                <span className=" text-blue-800"> TanoEd </span> -  Dynamisez votre enseignement des mathématiques </h1>
           {!isextraSm && <p className=" text-left font-bold  4xl:text-xl 3xl:text-lg  2xl:text-base xl:text-sm lg:text-xs 
            text-lg
            tracking-wide">              
Spécialisée dans les mathématiques, TanoEd est votre plateforme éducative de choix pour centraliser et gérer avec facilité les évaluations 
normalisées. Suivez les progrès de vos classes et adaptez vos pratiques pédagogiques grâce à nos outils avancés.
            </p> }

           {(!isMd && !isextraSm) &&  <button onClick= {handleProductsClick} className=" p-5 4xl:text-xl 3xl:text-lg 2xl:text-base xl:text-sm lg:text-xs font-bold tracking-wide bg-sky-900  shadow-sm shadow-black rounded-3xl text-white
            hover:scale-110 hover:transition-all hover:duration-300 hover:shadow-none hover:bg-sky-200 hover:text-black"> Découvrir  ⮕ </button>
    }
            </div>

        <div className=" flex flex-col lg:w-1/2 w-full h-full items-end justify-center align-middle ">
         
           <img src={landingImage} alt="landing" className='  h-auto w-auto '  />
          
</div> 

{(isMd || isextraSm) &&  <button onClick= {handleProductsClick} className=" p-5 4xl:text-xl 3xl:text-lg 2xl:text-base xl:text-sm lg:text-xs font-bold tracking-wide bg-sky-900  shadow-sm shadow-black rounded-3xl text-white
            hover:scale-110 hover:transition-all hover:duration-300 hover:shadow-none hover:bg-sky-200 hover:text-black"> Découvrir  ⮕ </button>
    }

        </div>

    
      
    
       
      </div>

  
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
      <path fill="#a2d9ff" fillOpacity="1" d="M0,32L48,53.3C96,75,192,117,288,154.7C384,192,480,224,576,208C672,192,768,128,864,106.7C960,85,1056,107,1152,112C1248,117,1344,107,1392,101.3L1440,96L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path>
    </svg>

    </> 
    );
  }
  
  export default Banner;