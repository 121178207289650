import React, {} from 'react';
import idea4Logo from '../assets/idea4.png';
import idea1Logo from '../assets/idea1.png';
import idea2Logo from '../assets/idea2.png';
import idea3Logo from '../assets/idea3.png';

function Idea() {
  

    return (
  
  <div className=' flex mt-20   tracking-wide ' >

    <div className=' w-1/4 2xl:w-1/6 xl:w-1/12 lg:w-1/12'> </div> 

<div className=" flex lg:flex-col gap-20 h-fit bg-white   w-full ">
<div className=' flex lg:flex-row flex-col justify-between w-full text-left  gap-20'>

<div className=' flex flex-col gap-8 p-5  bg-sky-100   rounded-3xl justify-start items-start shadow-sm shadow-sky-800'> 
<img src={idea1Logo} alt="idea4logo" className='  4xl:w-20 3xl:w-16 xl:w-14 lg:w-10  w-20'  />  
   
<h1 className=' 4xl:text-2xl 3xl:text-xl 2xl:text-base lg:text-sm font-bold'> Analyse rapide et intuitive </h1>
<p className=' 4xl:text-lg 3xl:text-md xl:text-sm lg:text-xs'> Gagnez du temps avec une analyse tout-en-un des résultats de classe, visualisez pourcentages, réponses élèves et images associées sur une seule page.</p>
 </div>


 <div className=' flex flex-col gap-8 p-5 bg-sky-100   rounded-3xl justify-start items-start shadow-sm shadow-sky-800'> 
 <img src={idea2Logo} alt="idea4logo" className='  4xl:w-20 3xl:w-16  xl:w-14 lg:w-10  w-20' />  
   
<h1 className=' 4xl:text-2xl 3xl:text-xl 2xl:text-base lg:text-sm font-bold'> Création et correction automatique de QCM </h1>
<p className=' 4xl:text-lg 3xl:text-md xl:text-sm lg:text-xs'> Créez des évaluations en ligne, corrigez automatiquement les QCM et évaluez la progression des élèves instantanément avec le scan de QCM. </p>
 </div>


 <div className=' flex flex-col gap-8 p-5  bg-sky-100   rounded-3xl justify-start items-start shadow-sm shadow-sky-800'> 
 <img src={idea3Logo} alt="idea4logo" className='  4xl:w-20 3xl:w-16  xl:w-14 lg:w-10  w-20' />  
    
<h1 className=' 4xl:text-2xl 3xl:text-xl 2xl:text-base lg:text-sm font-bold'> Outils de différenciation pédagogique </h1>
<p className=' 4xl:text-lg 3xl:text-md xl:text-sm lg:text-xs'> Mesurez la progression par rapport aux évaluations nationales et créez des progressions et groupes de besoins personnalisés pour chaque élève.</p>
 </div>


 <div className='flex flex-col gap-5 p-5  bg-sky-100   rounded-3xl justify-start items-start shadow-sm shadow-sky-800'> 

    <img src={idea4Logo} alt="idea4logo" className='  4xl:w-20 3xl:w-16  xl:w-14 lg:w-10  w-20' />  
   

  <h1 className='4xl:text-2xl 3xl:text-xl 2xl:text-base lg:text-sm font-bold'>Collaboration et gestion simplifiée</h1>
  <p className='4xl:text-lg 3xl:text-md xl:text-sm lg:text-xs'>
    Partagez et modifiez des plans de progression, suivez la réussite par thème, et gérez des groupes de besoins avec des scores détaillés.
  </p>
</div>

</div>
</div>




<div className=' w-1/4 2xl:w-1/6 xl:w-1/12 lg:w-1/12'> </div> 
</div>
    );
  }
  
  export default Idea;


