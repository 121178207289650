import React from 'react';
function Coordonnées({handleMentionClick, handlePolitiqueClick}) {
  
    return (
  <div className=' flex flex-col md:flex-row gap-5 w-full bg-slate-100'> 
  <div className=' flex sm:flex-row  flex-col p-5   text-xs  font-sans
  w-full h-full m-5 items-center align-middle text-center
  gap-10 justify-center tracking-wide bg-slate-100  ' >

<p> Tous droits réservés </p>


<p onClick={() => handleMentionClick()} className='hover:underline hover:cursor-pointer'> Mentions légales </p>
<p onClick={() => handlePolitiqueClick() } className=' hover:underline hover:cursor-pointer'> Politique de confidentialité  </p>
<p> Copyright © 2024 TanoSolutions </p>


</div>


</div>
    );
  }
  
  export default Coordonnées;


