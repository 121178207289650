// Dans Header.js
import React, {useState} from "react";
import logo from '../assets/logoTanoEd.png';
import lightmodo from '../assets/night-mode.png';
import { useMediaQuery } from 'react-responsive';
function Header({handleAccueilClick, handleProductsClick, handleWhyClick, handleProjetClick, handleContactFormClick}) {
  const isMd = useMediaQuery({ minWidth: 100, maxWidth: 810 });
  const [isMenuOpen, setIsMenuOpen] = useState(false);


  const handleContactClick = () => {
    handleContactFormClick()
    setIsMenuOpen(!isMenuOpen)
  }
  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const handleMenuAccueilClick = () => {
    handleAccueilClick()
    setIsMenuOpen(!isMenuOpen);
  }
  const handleMenuProductsClick = () => {
    handleProductsClick();
    setIsMenuOpen(!isMenuOpen)
  }

  const handleMenuWhyClick = () => {
    handleWhyClick();
    setIsMenuOpen(!isMenuOpen);
  }

  const handleMenuProjetClick = () => {
    handleProjetClick();
    setIsMenuOpen(!isMenuOpen);
  }
    return (
   <div >
   {!isMd &&  <div className="   fixed top-0 bg-opacity-80 w-full flex flex-row gap-5 justify-center  tracking-wide items-center bg-white p-2  z-50">
    <div className=" w-2/4"> </div> 
       <img src={logo} className='  2xl:w-24 xl:w-16 lg:w-14 w-10'  alt="logo " />
        <ul className=' flex flex-row 2xl:text-xl  xl:text-base lg:text-sm text-xs 
        
        font-sans  gap-5  w-full  '>
            <li  onClick={handleAccueilClick} className=' 2xl:p-3 p-2  hover:bg-zinc-100 rounded-2xl hover:border-zinc-400 border-2 border-transparent
            hover:cursor-pointer '> Accueil</li>
              <li onClick ={handleProductsClick}className=' 2xl:p-3  p-2 hover:bg-zinc-100 rounded-2xl hover:border-zinc-400 border-2 border-transparent
            hover:cursor-pointer '> Fonctionnalités</li>
                 <li onClick={handleProjetClick} className=' 2xl:p-3 p-2 hover:bg-zinc-100 rounded-2xl hover:border-zinc-400 border-2 border-transparent
            hover:cursor-pointer '> A Venir</li>
             <li onClick={handleWhyClick} className='  2xl:p-3 p-2 hover:bg-zinc-100 rounded-2xl hover:border-zinc-400 border-2 border-transparent
            hover:cursor-pointer '> Pourquoi ? </li>
         
            </ul>

        <p className=' w-1/6'> </p>
<button onClick={handleContactFormClick} className=' xl:p-5 lg:text-sm text-xs lg:p-3 bg-sky-700 rounded-2xl text-white  font-bold  hover:cursor-pointer 
hover:bg-sky-100 hover:text-black hover:transition hover:duration-1000 hover:scale-150
hover:shadow-sm hover:border-4 hover:border-sky-700 2xl:w-1/6 '> Obtenir TANOED </button>
<img src={lightmodo} className=' p-2 rounded-2xl shadow-zinc-500 shadow-sm border-2 border-zinc-200 hover:bg-zinc-200 hover:cursor-pointer lg:w-11 w-8' alt="light" /> 
  
  </div>}

  {isMd && 
    <div className="flex items-center justify-between p-5 bg-white shadow-md w-full fixed top-0 bg-opacity-80 z-40">
      <img src={logo} className="xl:w-16 lg:w-14 w-10" alt="logo" />
      <div className="flex items-center space-x-5">
        <img
          src={lightmodo}
          className="p-2 rounded-2xl shadow-zinc-500 shadow-sm border-2 border-zinc-200 hover:bg-zinc-200 hover:cursor-pointer lg:w-11 w-8"
          alt="light"
        />
        <button
          className="text-2xl lg:hidden"
          onClick={handleMenuToggle}
        >
          ☰
        </button>
      </div>
      <ul className={`flex-col 
      
       items-center   ${isMenuOpen ? 'flex h-[50vh]' : 'hidden'} 
      absolute  top-full left-0 w-full  bg-white  p-5 
       font-sans text-left text-xl`}>
        <li onClick={handleMenuAccueilClick} className=" p-2  w-full border-b-2 border-slate-400 hover:cursor-pointer hover:bg-sky-200">
          Accueil
        </li>
        <li onClick={handleMenuProductsClick} className="p-2  w-full border-b-2 border-slate-400 hover:cursor-pointer hover:bg-sky-200">
        Fonctionnalités
        </li>
        <li onClick={handleMenuProjetClick} className="p-2  w-full border-b-2 border-slate-400 hover:cursor-pointer hover:bg-sky-200">
          A Venir
        </li>
        <li onClick={handleMenuWhyClick} className="p-2  w-full border-b-2 border-slate-400 hover:cursor-pointer hover:bg-sky-200">
          Pourquoi ?
        </li>
        <button onClick={handleContactClick} className=" p-5 m-10 bg-sky-700 rounded-2xl text-white font-normal 
         hover:cursor-pointer hover:bg-sky-100 hover:text-black hover:transition hover:duration-1000 hover:scale-150
         hover:shadow-sm hover:border-4 hover:border-sky-700">
          Obtenir TANOED
        </button>
      </ul>
    </div>
    
    }
  
    </div>
    );
  }
  
  export default Header;