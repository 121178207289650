import React from 'react';


function GetIt({handleContactFormClick}) {


    return (
  
  <div className=' flex  w-full mt-5  tracking-wide justify-center ' style={{backgroundImage: 'linear-gradient(90deg, #5de0e6, #004aad)'}}>
<div  className=' flex p-10  flex-col gap-5 2xl:w-1/4 xl:w-1/3 lg:w-1/3  w-full h-fit items-center'>

  <h1 className='p-2  font-sans text-sm text-slate-100'> Commencer maintenant !</h1>
   
   <p className='text-slate-200 text-4xl xl:text-2xl lg:text-xl'> <span className=' text-white  font-sans font-bold'>Accélérez </span> votre gestion éducative</p>
    <p className=' lg:text-slate-300 p-2 text-base xl:text-base lg:text-sm
    text-white'> Découvrez comment TANOED peut transformer votre façon de travailler et simplifier l'évaluation et la progression des élèves.</p>
<button onClick={handleContactFormClick} className=' p-5 rounded-3xl bg-slate-200 w-fit font-bold items-center
border-2 border-sky-800 shadow-sm shadow-zinc-600
hover:shadow hover:border-transparent
'> Obtenir TanoEd ! </button>
</div>
   
</div>
    );
  }
  
  export default GetIt;