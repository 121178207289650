import React from 'react';

function Why({sectionRef}) {
  

    return (
  
  <div ref={sectionRef} className=' flex mt-56 mb-5 tracking-wide ' >

   
<div className=' flex flex-col lg:w-1/4 w-1/6 '> </div>

<div className=" flex flex-col gap-20 bg-white  md:m-20 lg:mt-20  lg:w-1/2 md:w-full ">
<h1 className=' font-sans tracking-wide  4xl:text-3xl 3xl:text-2xl  2xl:text-xl xl:text-xl 
lg:text-base md:text-xl text-lg uppercase text-left  '> Pourquoi utiliser TanoEd ? </h1>
<h1 className=' font-sans tracking-wide 4xl:text-4xl 3xl:text-3xl 2xl:text-2xl xl:text-2xl
 lg:text-base text-left md:text-2xl text-xl'> Maximisez l'efficacité pédagogique : <span className=' text-blue-600'> 
 gain de temps </span> et <span className=' text-blue-600'>amélioration des résultats </span> éducatifs. 
 </h1>
</div>


<div className=' lg:w-1/2 '> </div> 
<div className=' lg:w-1/4 '> </div> 
</div>
    );
  }
  
  export default Why;